import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useSelectedConsumer } from '../App';

function ConsumerForm({ onConsumerChange }) {
  const [isLoading, setIsLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success');
  const [consumers, setConsumers] = useState([]);
  const { selectedConsumer } = useSelectedConsumer();
  //const [selectedConsumer, setSelectedConsumer] = useState('');
  const [consumerProfile, setConsumerProfile] = useState('');
  const [billingData, setBillingData] = useState('');
  const [invoiceData, setInvoiceData] = useState('');
  const [usageData, setUsageData] = useState('');
  const [servicePointData, setServicePointData] = useState('');
  const [personalInfo, setPersonalInfo] = useState('');
  const [accountInfo, setAccountInfo] = useState('');
  const [adatreeAccessToken, setAdatreeAccessToken] = useState(undefined);
  const [adatreeCdrArrangementId, setAdatreeCdrArrangementId] = useState(undefined);

  /*
    useEffect(() => {
      setIsLoading(true);
      axios.get(process.env.REACT_APP_API_URL + 'consumerIds')
        .then(response => {
          setConsumers(response.data);
        })
        .catch(error => {
          console.error('Error fetching consumer IDs:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, []);*/

  useEffect(() => {
    if (selectedConsumer) {
      setIsLoading(true);
      axios.get(`${process.env.REACT_APP_API_URL}consumers/${selectedConsumer}`)
        .then(response => {
          const consumer = response.data;
          setConsumerProfile(JSON.stringify(consumer.profile, null, 2));
          setBillingData(JSON.stringify(consumer.billing, null, 2));
          setInvoiceData(JSON.stringify(consumer.invoices, null, 2));
          setServicePointData(JSON.stringify(consumer.servicePoints, null, 2));
          setUsageData(JSON.stringify(consumer.usage, null, 2));
          setPersonalInfo(JSON.stringify(consumer.personal, null, 2));
          setAccountInfo(JSON.stringify(consumer.accounts, null, 2));
        })
        .catch(error => {
          console.error('Error fetching consumer details:', error);
          showToast('fail', `Error fetching consumer data! ${JSON.stringify(error)}`);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setConsumerProfile('');
      setBillingData('');
      setUsageData('');
      setPersonalInfo('');
      setAccountInfo('');
    }
  }, [selectedConsumer]);

  // Adding and removing the keypress event listener
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    // Cleanup function
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []); // Empty dependency array ensures this only runs once


  const handleSave = () => {
    setIsLoading(true);

    let data;

    try {
    data = {
      consumerProfile: JSON.parse(consumerProfile),
      billingData: JSON.parse(billingData),
      invoiceData: JSON.parse(invoiceData),
      servicePointData: JSON.parse(servicePointData),
      usageData: JSON.parse(usageData),
      personalInfo: JSON.parse(personalInfo),
      accountInfo: JSON.parse(accountInfo),
    };
  } catch (error) {
    console.error(`Could not parse data`, error);
    setIsLoading(false);
    showToast('fail', `Could not parse data! ${error}`);
    return;
  }

    if (selectedConsumer) {
      // If a consumer is selected, update the existing consumer
      axios.put(`${process.env.REACT_APP_API_URL}consumers/${selectedConsumer}`, data)
        .then(response => {
          console.log('Consumer updated:', response.data);
          showToast('success', 'Consumer updated OK ✅');
        })
        .catch(error => {
          showToast('fail', `Error updating consumer! ${JSON.stringify(error)}`);
          console.error('Error updating consumer:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      // If no consumer is selected, create a new consumer
      axios.post(process.env.REACT_APP_API_URL + 'consumers', data)
        .then(response => {
          console.log('New consumer created:', response.data);
          showToast('success', 'New consumer created ✅');
        })
        .catch(error => {
          console.error('Error creating consumer:', error);
          showToast('fail', `Error creating new consumer! ${JSON.stringify(error)}`);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // Ref to hold the latest version of handleSave
  const handleSaveRef = useRef(handleSave);

  // Update the ref each time handleSave changes
  useEffect(() => {
    handleSaveRef.current = handleSave;
  }, [handleSave]);

  const loadDataFromAdatree = () => {
    if (!adatreeAccessToken) return;

    setIsLoading(true);

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}adatreeData`,
      headers: {
        'X-AAT': adatreeAccessToken,
        'X-ACAID': adatreeCdrArrangementId
      }
    };

    axios.request(config)
      .then((response) => {
        //console.log(JSON.stringify(response.data));
        updatePersonalInfo(response.data.adatreeCustomerData);
        updateAccountInfo(response.data.adatreeAccountData);
        updateInvoiceData(response.data.adatreeInvoicesData);
        updateBillingTransactionData(response.data.adatreeBillsData);
        updateServicePointData(response.data.adatreeServicepointsData);
        updateUsageData(response.data.adatreeUsageData);
        let customer = response.data.adatreeCustomerData.data.customers[0];
        let profile = {
          id: {
            name: customer[customer.customerUType].firstName + ' ' + customer[customer.customerUType].lastName,
            email: customer[customer.customerUType].emailAddresses[0] ? customer[customer.customerUType].emailAddresses[0].address.toLowerCase() : "CHANGEME@NOW.COM"
          },
          controlledLoad: {
            appliances: []
          }
        }
        setConsumerProfile(JSON.stringify(profile, null, 4));
        setIsLoading(false);
        showToast('success', `Succesfully loaded the consumer data from Adatree`)
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        showToast('fail', `Error getting data from Adatree: ${JSON.stringify(error)}`);
      });
  }

  const showToast = (type, message) => {
    setMessage(message);
    setShowMessage(true);
    setMessageType(type);
    // Hide the message after n seconds
    /*
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
    */

  }

  const closeToast = () => {
    setShowMessage(false);
  };

  // Key down handler
  const handleKeyDown = (event) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 's') {
      event.preventDefault(); // Prevent the browser's save dialog from opening
      handleSaveRef.current(); // Use the current version of handleSave
    }
  };

  const updatePersonalInfo = (newInfoObj) => {
    //If no data returned then there is nothing to do
    if (newInfoObj == null || newInfoObj.data == null || newInfoObj.data.customers == null) {
      console.error("Invalid data format found when updating personal info");
      return;
    }

    //Replace existing personal info without modification. This ensures names, email, phone number and addresses all stay up to date.

    //Add lastRetrieved property to each customer record
    const today = new Date();
    newInfoObj.data.customers.forEach(newCustomerObj => {
      newCustomerObj.automised = { lastRetrieved: today.toISOString() }
    });

    setPersonalInfo(JSON.stringify(newInfoObj, null, 4));
  }

  const updateAccountInfo = (newInfoObj) => {
    // Validate input structure
    if (!newInfoObj || !newInfoObj.data || !newInfoObj.data.accounts) {
      const msg = "Invalid data format found when updating account info"
      console.error(msg);
      throw new Error(msg);
    }

    if (newInfoObj.meta.totalPages > 1) {
      const msg = `Adatree returned more than one page of records of account info!`;
      console.log(msg)
      throw new Error(msg)
    }

    const newAccountsAr = newInfoObj.data.accounts;
    const today = new Date();
    let updatedAccountInfo = JSON.parse(accountInfo || '{"data": {"accounts": []}}'); // Assuming accountInfo is a JSON string
    const oldAccountsAr = updatedAccountInfo.data.accounts;

    // Process each account in the new info
    newAccountsAr.forEach(newAccountObj => {
      // Ensure each new account has a structured place for metadata
      newAccountObj.automised = { lastRetrieved: today.toISOString() };

      // Determine if the account already exists
      let existingAccountIndex = oldAccountsAr.findIndex(acct => acct.accountId === newAccountObj.accountId);
      if (existingAccountIndex === -1) {
        // Add new account
        oldAccountsAr.push(newAccountObj);
      } else {
        // Update existing account
        oldAccountsAr[existingAccountIndex] = newAccountObj;
      }
    });

    // Update global account info
    setAccountInfo(JSON.stringify(updatedAccountInfo, null, 4));

  };

  const updateInvoiceData = (newInfoObj) => {
    // Validate input structure
    if (!newInfoObj || !newInfoObj.data || !newInfoObj.data.invoices) {
      const msg = "Invalid data format found when updating invoice info"
      console.error(msg);
      throw new Error(msg);
    }

    if (newInfoObj.meta.totalPages > 1) {
      const msg = `Adatree returned more than one page of records of invoice info!`;
      console.log(msg)
      throw new Error(msg)
    }

    const newInvoicesAr = newInfoObj.data.invoices;
    const today = new Date();
    let updatedInvoiceInfo = JSON.parse(invoiceData || '{"data": {"invoices": []}}'); // Assuming invoiceData is a JSON string
    const oldInvoicesAr = updatedInvoiceInfo.data.invoices;

    // Process each account in the new info
    newInvoicesAr.forEach(newInvoiceObj => {
      // Ensure each new invoice has a structured place for metadata
      newInvoiceObj.automised = { lastRetrieved: today.toISOString() };

      // Determine if the invoice for the account already exists
      let existingAccountIndex = oldInvoicesAr.findIndex(invoice => invoice.accountId === newInvoiceObj.accountId && invoice.invoiceNumber === newInvoiceObj.invoiceNumber);
      if (existingAccountIndex === -1) { //Invoice not found in existing array, so add it
        // Add new invoice
        oldInvoicesAr.push(newInvoiceObj);
      } else {
        // Update existing account to ensure we always have the latest
        oldInvoicesAr[existingAccountIndex] = newInvoiceObj;
      }
    });

    // Update global account info
    setInvoiceData(JSON.stringify(updatedInvoiceInfo, null, 4));
  };

  const updateBillingTransactionData = (newInfoObj) => {
    // Validate input structure
    if (!newInfoObj || !newInfoObj.data || !newInfoObj.data.transactions) {
      const msg = "Invalid data format found when updating billing transaction info";
      console.error(msg);
      throw new Error(msg);
    }

    if (newInfoObj.meta.totalPages > 1) {
      const msg = `Adatree returned more than one page of records of billing transaction info!`;
      console.log(msg);
      throw new Error(msg);
    }

    const newTransactions = newInfoObj.data.transactions;
    const today = new Date();
    let updatedTransactionInfo = JSON.parse(billingData || '{"data": {"transactions": []}}');
    const oldTransactions = updatedTransactionInfo.data.transactions;

    // Process each transaction in the new info
    newTransactions.forEach(newTransactionObj => {
      // Ensure each new transaction has a structured place for metadata
      newTransactionObj.automised = { lastRetrieved: today.toISOString() };

      // Create a hash of the transaction object, excluding the 'adatree' property
      const newTransactionHash = JSON.stringify({ ...newTransactionObj, automised: undefined, adatree: undefined });

      // Determine if the transaction already exists by comparing hashes
      let existingTransactionIndex = oldTransactions.findIndex(oldTransaction =>
        JSON.stringify({ ...oldTransaction, automised: undefined, adatree: undefined }) === newTransactionHash);

      if (existingTransactionIndex === -1) { // Transaction not found, so add it
        oldTransactions.push(newTransactionObj);
      } else {
        // Update existing transaction to ensure we always have the latest
        oldTransactions[existingTransactionIndex] = newTransactionObj;
      }
    });

    // Update global transaction info
    setBillingData(JSON.stringify(updatedTransactionInfo, null, 4));
  };

  const updateServicePointData = (newInfoObj) => {
    // Validate input structure
    if (!newInfoObj || !newInfoObj.data || !newInfoObj.data.servicePoints) {
      const msg = "Invalid data format found when updating service point info";
      console.error(msg);
      throw new Error(msg);
    }

    if (newInfoObj.meta.totalPages > 1) {
      const msg = `Adatree returned more than one page of records of service point info!`;
      console.log(msg);
      throw new Error(msg);
    }

    const newServicePoints = newInfoObj.data.servicePoints;
    const today = new Date();
    let updatedServicePointInfo = JSON.parse(servicePointData || '{"data": {"servicePoints": []}}');
    const oldServicePoints = updatedServicePointInfo.data.servicePoints;

    // Process each service point in the new info
    newServicePoints.forEach(newServicePointObj => {
      // Ensure each new service point has a structured place for metadata
      newServicePointObj.automised = { lastRetrieved: today.toISOString() };

      // Determine if the service point already exists by comparing servicePointId and nationalMeteringId
      let existingServicePointIndex = oldServicePoints.findIndex(oldServicePoint =>
        oldServicePoint.servicePointId === newServicePointObj.servicePointId &&
        oldServicePoint.nationalMeteringId === newServicePointObj.nationalMeteringId);

      if (existingServicePointIndex === -1) { // Service point not found, so add it
        oldServicePoints.push(newServicePointObj);
      } else {
        // Update existing service point to ensure we always have the latest
        oldServicePoints[existingServicePointIndex] = newServicePointObj;
      }
    });

    // Update global service point info
    setServicePointData(JSON.stringify(updatedServicePointInfo, null, 4));
  };

  const updateUsageData = (newInfoObj) => {
    // Validate input structure
    if (!newInfoObj || !newInfoObj.data || !newInfoObj.data.reads) {
      const msg = "Invalid data format found when updating usage read info";
      console.error(msg);
      throw new Error(msg);
    }

    if (newInfoObj.meta.totalPages > 1) {
      const msg = `Adatree returned more than one page of records of usage read info!`;
      console.log(msg);
      throw new Error(msg);
    }

    const newReads = newInfoObj.data.reads;
    const today = new Date();
    let updatedReadInfo = JSON.parse(usageData || '{"data": {"reads": []}}');
    const oldReads = updatedReadInfo.data.reads;

    // Process each read in the new info
    newReads.forEach(newReadObj => {
      // Ensure each new read has a structured place for metadata
      newReadObj.automised = { lastRetrieved: today.toISOString() };

      // Determine if the read already exists by comparing servicePointId, registerSuffix, readStartDate, and readUType
      let existingReadIndex = oldReads.findIndex(oldRead =>
        oldRead.servicePointId === newReadObj.servicePointId &&
        oldRead.registerSuffix === newReadObj.registerSuffix &&
        oldRead.readStartDate === newReadObj.readStartDate &&
        oldRead.readUType === newReadObj.readUType);

      if (existingReadIndex === -1) { // Read not found, so add it
        oldReads.push(newReadObj);
      } else {
        // Update existing read to ensure we always have the latest
        oldReads[existingReadIndex] = newReadObj;
      }
    });

    // Update global read info
    setUsageData(JSON.stringify(updatedReadInfo, null, 4));
  };


  return (
    <div className="contentBody">
      {showMessage && <div className={`flash-banner ${messageType}`}>
        {message}
        <button onClick={closeToast} className="close-toast">
          &times; {/* This is a simple cross character, you can use an icon here */}
        </button>
      </div>}
      <h2>Consumer Admin - {selectedConsumer}</h2>
      <label>Adatree AccessToken: <input type="text" id="adatreeAccessToken" defaultValue={adatreeAccessToken} onChange={(e) => setAdatreeAccessToken(e.target.value)}></input></label>
      <br />
      <label>Adatree CDR Arrangement ID: <input type="text" id="adatreeCdrArrangementId" defaultValue={adatreeCdrArrangementId}></input></label>
      <br />
      <button onClick={loadDataFromAdatree}>Get data from Adatree</button>

      {isLoading && (
        <div style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          background: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 9999,
        }}>
          {/* Replace "Loading..." with your actual loading spinner */}
          <div>Loading...</div>
        </div>
      )}

      <label id="consumerProfile" className="inputBlock">Consumer profile:
        <textarea
          value={consumerProfile}
          onChange={(e) => setConsumerProfile(e.target.value)}
          placeholder="Enter consumer profile here"
        />
        <div className="dataTemplate">
          {/*consumerProfileDataTemplate*/}
        </div>
      </label>

      <label id="consumerPersonal" className="inputBlock">Personal:
        <textarea
          value={personalInfo}
          onChange={(e) => setPersonalInfo(e.target.value)}
          placeholder="Enter personal information here"
        /></label>

      <label id="consumerAccounts" className="inputBlock">Consumer accounts:
        <textarea
          value={accountInfo}
          onChange={(e) => setAccountInfo(e.target.value)}
          placeholder="Enter account information here"
        />
      </label>

      <label id="consumerInvoices" className="inputBlock">Consumer invoices:
        <textarea
          value={invoiceData}
          onChange={(e) => setInvoiceData(e.target.value)}
          placeholder="Enter invoice data here"
        />
        <div className="dataTemplate">
          {/*consumerInvoiceDataTemplate*/}
        </div>
      </label>

      <label id="consumerBills" className="inputBlock">Consumer bills:
        <textarea
          value={billingData}
          onChange={(e) => setBillingData(e.target.value)}
          placeholder="Enter billing data here"
        />
        <div className="dataTemplate">
          {/*consumerBillsDataTemplate*/}
        </div>
      </label>

      <label id="consumerServicePoints" className="inputBlock">Consumer service points:
        <textarea
          value={servicePointData}
          onChange={(e) => setServicePointData(e.target.value)}
          placeholder="Enter service point data here"
        />
        <div className="dataTemplate">
          {/*consumerServicePointDataTemplate*/}
        </div>
      </label>

      <label id="consumerUsage" className="inputBlock">Consumer usage:
        <textarea
          value={usageData}
          onChange={(e) => setUsageData(e.target.value)}
          placeholder="Enter usage data here"
        />
        <div className="dataTemplate">
          {/*consumerUsageDataTemplate*/}
        </div>
      </label>

      <button onClick={handleSave}>Save</button>
    </div>
  );
}

const consumerProfileDataTemplate = `{
  "id": {
    "name": "John Smith",
    "email": "jsmith@gmail.com"
  },
  "importance": {
    "cost": 5,
    "environment": 5,
    "vendor": {
      "support": 5,
      "easeOfUse": 5,
      "australianOwned": 5,
      "communityMinded": 5
    }
  },
  "meta": {
    "bedrooms": 4,
    "occupants": 5,
    "occupancy": "owner|renting",
    "notes": ""
  },
  "solar": {
    "inverterCapacity": 1,
    "solarArray": 1.3
  },
  "controlledLoad": {
    "appliances": ["HOT_WATER", "POOL_PUMP", "SLAB_HEATING"]
  },
  "deprecated": {
    "postcode": 2358,
    "state": "NSW",
    "hasSolar": true,
    "hasSmartMeter": true,
    "hasBasicMeter": false,
    "hasHouseBattery": false,
    "electricityMeterType": "Type 4",
    "membership": "NRMA",
    "isBusiness": false,
    "currentSupplierBrand": "mojo",
    "currentPricingModel": "TIME_OF_USE_CONT_LOAD",
    "readingsType": "interval|aggregate",
    "equipmentSupplier": null,
    "thirdParty": null,
    "averageDailyElectricityUsage": "16kWh",
    "loyaltyMemberships": [],
    "groupBuyParticipation": false,
    "contingentPlanEnrolled": false,
    "hasControlledLoad": true,
    "controlledLoadType": "CL1",
    "controlledLoadDevices": ["HOT_WATER", "POOL_PUMP", "SLAB_HEATING", etc],
    "dataSource": "CDR",
    "purchasedSolarFromOrigin": false,
  }
}`;

const consumerInvoiceDataTemplate = `{

}`;

const consumerServicePointDataTemplate = `{

}`;

const consumerBillsDataTemplate = `{
    "data": {
      "transactions": [
        {
          "transactionUType": "usage",
          "usage": {
            "timeOfUseType": "PEAK",
            "description": "GENERAL_USAGE",
            "startDate": "2023-01-24T00:00:00+11:00",
            "endDate": "2023-02-23T00:00:00+11:00",
            "measureUnit": "KWH",
            "usage": 368.57,
            "amount": "115.54",
          }
        },
        {
          "transactionUType": "usage",
          "usage": {
            "timeOfUseType": "PEAK",
            "description": "CONTROLLED_LOAD_1_USAGE",
            "startDate": "2023-01-24T00:00:00+11:00",
            "endDate": "2023-02-23T00:00:00+11:00",
            "measureUnit": "KWH",
            "usage": 67.697,
            "amount": "14.82",
          }
        },
        {
          "transactionUType": "usage",
          "usage": {
            "timeOfUseType": "SOLAR",
            "description": "FEED_IN_CREDIT",
            "startDate": "2023-01-24T00:00:00+11:00",
            "endDate": "2023-02-23T00:00:00+11:00",
            "measureUnit": "KWH",
            "usage": 169.8645,
            "amount": "-33.97",
          }
        },
        {
          "transactionUType": "usage",
          "usage": {
            "timeOfUseType": "AGGREGATE",
            "description": "DAILY_SUPPLY",
            "startDate": "2023-01-24T00:00:00+11:00",
            "endDate": "2023-02-23T00:00:00+11:00",
            "measureUnit": "DAYS",
            "usage": 31,
            "amount": "52.01",
          }
        },
        {
          "transactionUType": "usage",
          "usage": {
            "timeOfUseType": "AGGREGATE",
            "description": "DAILY_SUPPLY_CONTROLLED",
            "startDate": "2022-12-24T00:00:00+11:00",
            "endDate": "2023-01-18T00:00:00+11:00",
            "measureUnit": "DAYS",
            "usage": 26,
            "amount": "2",
          }
        }
      ]
    },
    "meta": {
        "consumerId": "john.smith.1234",
        "source": "CDR"
    }
}`;

const consumerUsageDataTemplate = `{
    "data": "Interval,210238186 B1 (kWh) (export),210238186 E1 (kWh) (general),210238186 E2 (kWh) (CL1)\n      09/03/2021 0:30,0,0.143,0\n      09/03/2021 1:00,0,0.138,0\n      09/03/2021 1:30,0,0.104,0\n      09/03/2021 2:00,0,0.141,0\n      09/03/2021 2:30,0,0.121,0\n      09/03/2021 3:00,0,0.127,0\n      09/03/2021 3:30,0,0.117,0\n      09/03/2021 4:00,0,0.124,0",
    "meta": {
        "consumerId": "john.smith.1234",
        "source": "DNSP-ESSENTIAL",
        "format": "CSV"
    }
}`;



export default ConsumerForm;
